@import 'stylesheets/burgers.scss';

    .hamburger{    
      width:40px;
      height:40px;
      background-color:transparent;
      margin-left:auto;
      margin-top:20px;
      padding:0px;

/*      .hamburger-box{
        height:40px;
      }
*/
      .hamburger-inner{
        background-color:#fff !important;
        width:30px;
        height:2px;
        &:after,
        &:before{
          width:30px;
          height:2px;
          background-color:#fff !important;
        }
      }
    }